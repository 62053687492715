import { Button, Divider, Link, Menu, MenuItem } from '@mui/material'
import { useState, type ReactNode } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import type { ClientAuthenticatedUser } from '../../lib/auth'
import type { RequestUrl } from '../../lib/url'

type Props = {
  children: ReactNode
  user: ClientAuthenticatedUser | null
  requestUrl: RequestUrl
  headerContent?: ReactNode
  unsubscribe?: {
    subscription: PushSubscription
    onUnsubscribe: () => void
  }
  sha?: string | null
}

const pages = [
  { urlPath: '/', text: 'Callbox' },
  { urlPath: '/ringer', text: 'Ringer' },
]

export function FrameworkPage(props: Props) {
  const requestUrl = new URL(props.requestUrl)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <div className="flex flex-col gap-y-2">
      {props.user ? (
        <div className="flex flex-row justify-between items-center m-2">
          <div>
            <Button onClick={e => setAnchorEl(e.currentTarget)}>
              <MenuIcon />
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
              {pages.map(page => {
                return (
                  <MenuItem key={page.urlPath}>
                    <Button disabled={page.urlPath === requestUrl.pathname} LinkComponent={Link} href={page.urlPath}>
                      {page.text}
                    </Button>
                  </MenuItem>
                )
              })}

              <Divider />
              <MenuItem>
                <Button LinkComponent={Link} href={'/api/google/logout'}>
                  Logout
                </Button>
              </MenuItem>
              {props.unsubscribe ? (
                <>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="warning"
                      onClick={() => {
                        void props.unsubscribe?.subscription?.unsubscribe()
                        props.unsubscribe?.onUnsubscribe()
                      }}
                    >
                      Unsubscribe
                    </Button>
                  </MenuItem>
                </>
              ) : null}
            </Menu>
          </div>
          {props.user && props.sha ? (
            <div className="font-mono text-sm text-gray-500">{props.sha.slice(0, 8)}</div>
          ) : null}
        </div>
      ) : null}
      <div className="flex flex-col gap-y-1 justify-center items-center">{props.children}</div>
    </div>
  )
}
