import React from 'react'
import Head from 'next/head'
import type { GetServerSideProps } from 'next'
import type { ClientAuthenticatedUser } from '../lib/auth'
import { getAuthenticatedUser, toClientAuthenticatedUser } from '../lib/auth'
import type { CallboxLock, CallboxLockUpdate } from '../schema/CallboxLock'
import { CallboxLockSchema } from '../schema/CallboxLock'
import { getCallboxLock } from '../lib/callbox'
import type { ButtonProps } from '@mui/material'
import { Alert, Button } from '@mui/material'
import { askPermission, registerServiceWorker, subscribeUserToPush } from '../lib/notifications/ServiceWorker'
import { getEnvironment } from '../lib/environment'
import { Login } from '../components/Login'
import { FrameworkPage } from '../components/framework/FrameworkPage'
import type { RequestUrl } from '../lib/url'
import { getRequestUrl } from '../lib/url'
import { getFrontendLogger } from '../lib/logger'
import { UAParser } from 'ua-parser-js'
import { AsyncButton } from '../components/AsyncButton'
import { cn } from '../lib/styles'

type Props = {
  user: ClientAuthenticatedUser | null
  callboxLock: CallboxLock
  pushNotificationPublicKey: string
  requestUrl: RequestUrl
  sha: string | null
}

export const getServerSideProps = (async params => {
  const [callboxLock, user] = await Promise.all([getCallboxLock(), getAuthenticatedUser(params.req)])
  return {
    props: {
      user: user ? toClientAuthenticatedUser(user) : user,
      callboxLock,
      pushNotificationPublicKey: getEnvironment().VAPID_PUBLIC_KEY,
      requestUrl: getRequestUrl(params.req),
      sha: getEnvironment().VERCEL_GIT_COMMIT_SHA || 'local',
    },
  }
}) satisfies GetServerSideProps<Props>

export default function Home(props: Props) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [lockStatus, setLockStatus] = React.useState<CallboxLock>(props.callboxLock)
  const [registrationForPush, setRegistrationForPush] = React.useState<null | ServiceWorkerRegistration>(null)
  const [existingSubscription, setExistingSubscription] = React.useState<null | PushSubscription>(null)

  React.useEffect(() => {
    getFrontendLogger().info({ message: 'Rendering home page' })
    if (!props.user?.id) {
      setRegistrationForPush(null)
      setExistingSubscription(null)
      return
    }

    async function run() {
      const registration = await registerServiceWorker()
      await registration.update()
      const sub = await registration.pushManager.getSubscription()
      setRegistrationForPush(registration)
      if (sub) {
        setExistingSubscription(sub)
      }
    }

    run().catch(e => getFrontendLogger().error({ error: String(e) }))
  }, [props.user?.id, props.pushNotificationPublicKey])

  if (!props.user) {
    return <Login redirect="/" />
  }

  function createOnClick(update: CallboxLockUpdate) {
    return () => {
      setIsLoading(true)
      setError(null)
      return fetch('/api/callbox/lockStatus', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(update),
      })
        .then(res => res.json())
        .then(json => CallboxLockSchema.parse(json))
        .then(setLockStatus)
        .catch(setError)
        .finally(() => setIsLoading(false))
    }
  }

  const oneMinute = 1000 * 60
  const buttons: (ButtonProps & { display: string; time: number })[] = (
    [
      { display: '1 minute', time: oneMinute },
      { display: '5 minutes', time: 5 * oneMinute },
      { display: '1 hour', time: 60 * oneMinute },
      { display: 'Lock', time: Number.MIN_SAFE_INTEGER, color: 'error', variant: 'outlined' },
    ] as const
  ).map(props => ({
    ...props,
    onClick: createOnClick({ additionalTime: props.time }),
    disabled: isLoading,
  }))

  return (
    <>
      <Head>
        <title>701mn</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <FrameworkPage
        sha={props.sha}
        unsubscribe={
          existingSubscription
            ? {
                onUnsubscribe: () => {
                  const existing = existingSubscription
                  setExistingSubscription(null)
                  revokePush(existing).catch(e => getFrontendLogger().error({ error: String(e) }))
                },
                subscription: existingSubscription,
              }
            : undefined
        }
        user={props.user}
        requestUrl={props.requestUrl}
        headerContent={
          registrationForPush && !existingSubscription ? (
            <AsyncButton
              doAction={async () => {
                try {
                  const maybeSub = await requestPush(registrationForPush, props.pushNotificationPublicKey)
                  if (maybeSub) {
                    setExistingSubscription(maybeSub)
                  }
                } catch (e) {
                  return getFrontendLogger().error({
                    message: 'Error in requesting push',
                    error: String(e),
                  })
                }
              }}
            >
              Enable Push
            </AsyncButton>
          ) : null
        }
      >
        <div className="flex flex-col justify-center items-center gap-y-4">
          <h3 className="text-5xl">
            Callbox:{' '}
            <span
              className={cn({
                'text-red-700': lockStatus.color === 'red',
                'text-green-700': lockStatus.color === 'green',
              })}
            >
              {lockStatus.title}
            </span>
          </h3>
          <CallboxLockStatus lockStatus={lockStatus} />
          {error ? <Alert severity="error">{error.message}</Alert> : null}

          <div className="flex flex-col gap-y-2 w-80">
            {buttons.map(button => {
              const { display, time, ...buttonProps } = button
              return (
                <Button fullWidth className="py-4" variant="contained" {...buttonProps} key={time}>
                  {display}
                </Button>
              )
            })}
          </div>
        </div>
      </FrameworkPage>
    </>
  )
}

function CallboxLockStatus(props: { lockStatus: CallboxLock }) {
  if (!props.lockStatus.title) {
    return null
  }

  return (
    <h5 className={cn('text-2xl', { 'text-green-700': props.lockStatus.color === 'green' })}>
      {props.lockStatus.status}
    </h5>
  )
}

function revokePush(subscription: PushSubscription) {
  return fetch(`/api/notifications/register/${btoa(encodeURIComponent(subscription.endpoint))}`, {
    method: 'DELETE',
  })
}

async function requestPush(
  registration: ServiceWorkerRegistration,
  publicKey: string,
): Promise<null | PushSubscription> {
  const hasPermission = await askPermission()
  if (!hasPermission) {
    return null
  }

  const subscription = await subscribeUserToPush(registration, publicKey)

  const uaParser = new UAParser()
  const client = uaParser.getResult()
  await fetch('/api/notifications/register', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      client: {
        browser: client.browser.name,
        device: `${client.device.vendor} ${client.os.name}`,
      },
      ...subscription.toJSON(),
    }),
  })

  return subscription
}
